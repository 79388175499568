<template>
    <template v-if="isShopWithProgressionBar && cart?.items?.length && cart.freeShippingProgression">
        <span class="free-shipping-label">{{ $t('labels.freeShipping') }}</span>
        <div class="progress free-shipping-progressbar">
            <div class="progress-bar" role="progressbar" :style="'width: ' + cart.freeShippingProgression + '%'"
                 :aria-valuenow="cart.freeShippingProgression"
                 aria-valuemin="0"
                 aria-valuemax="100"></div>
        </div>
    </template>
</template>

<script>
import {useCartStore} from '@/store/cart';

export default {
    name: 'CartFreeShippingProgression',
    data() {
      return {
        isShopWithProgressionBar: false
      }
    },
    computed: {
        cart() {
            return useCartStore().cart;
        }
    },
    mounted() {
      window.shippingConfig?.freeShippingFromNumberOfProducts > -1 ? this.isShopWithProgressionBar = true : this.isShopWithProgressionBar = false;
    }
}
</script>