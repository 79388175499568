<template>
    <div v-if="description" v-html="renderHTML(description)" class="description"></div>
    <meta v-if="description" itemprop="description" :content="renderHTML(description)"/>
</template>

<script>
export default {
    name: 'ProductDescription',
    props: [],
    data() {
        return {
            description: null,
        }
    },
    mounted() {
        this.description = window.productDetails.description;
    }
}
</script>