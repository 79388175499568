import {createApp} from 'vue'
import {createPinia} from 'pinia'
import {createI18n} from 'vue-i18n'
import * as Sentry from "@sentry/vue";
import messages from './i18n/messages.json'

import App from './App.vue'
const pinia = createPinia()

const i18n = createI18n({
  locale: window.navigator.language.split('-')[0],
  fallbackLocale: 'fr' ,
  messages
});


const app = createApp(App)
  .use(pinia)
  .use(i18n)
;

if(process.env.NODE_ENV === 'development') {
    app.config.devtools = true;
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_SENTRY_ENV,
    integrations: [
    ],

    tracesSampleRate: 0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost"],

    beforeSend(event, hint) {
      if(typeof hint?.originalException === 'string' && hint?.originalException?.includes('obtp')) {
        return null;
      }
      return event
    }
  });
}

app.config.globalProperties.renderHTML = function (encodedHTML) {
  if (encodedHTML) {
    return decodeURIComponent(encodedHTML);
  } else {
    return '';
  }
};

app.mount('#app');
