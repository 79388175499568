<template>
    <template v-for="(product, id) in products">
        <div :class="`direct-buy-item ${product.code}`">
            <div class="row align-items-center">
                <div :class="classColumnOne">
                    <div class="product-image-wrapper">
                        <ProductImages :product="product"/>
                    </div>
                </div>
                <div :class="classColumnTwo">
                    <ProductName :product="product"/>
                    <ProductLongName :product="product"/>
                    <ProductShortDescription :product="product"/>
                    <ProductVisualStock :product="product"/>
                    <ProductConfigurableChoices
                        :product="product"
                        :index="id+1"
                        isFromList
                        :chooseChoiceSelected="chooseChoiceSelected"/>
                    <ProductArgument4 v-if="product.argument4" style="display: none;" data-product-component-type="productargument4" :product="product"/>
                    <ProductArgument5 v-if="product.argument5" style="display: none;" data-product-component-type="productargument5" :product="product"/>
                    <AddToCart showqty :item="product" :changeChoice="choicesSelected[id]"/>
                </div>
            </div>
        </div>
    </template>
</template>

<script>

import ProductImages from "@/components/product/Images.vue";
import ProductName from "@/components/product/Name.vue";
import ProductConfigurableChoices from "@/components/product/ProductConfigurableChoices.vue";
import ProductVisualStock from "@/components/product/VisualStock.vue";
import AddToCart from "@/components/AddToCart.vue";
import ProductLongName from "@/components/product/LongName.vue";
import ProductShortDescription from "@/components/product/ShortDescription.vue";
import ProductArgument4 from "@/components/product/Argument4.vue";
import ProductArgument5 from "@/components/product/Argument5.vue";

export default {
    name: 'ProductListDirectBuy',
    components: {
        ProductArgument4,
        ProductArgument5,
        ProductShortDescription,
        ProductLongName,
        AddToCart,
        ProductVisualStock,
        ProductConfigurableChoices,
        ProductName,
        ProductImages
    },
    props: {
        products: {
            type: Array,
            default: () => []
        },
        columnFormat: {
            type: String,
            default: '4/8'
        }
    },
    data() {
        return {
            choicesSelected: [],
            classColumnOne: 'col-lg-4 col-md-5',
            classColumnTwo: 'col-lg-8 col-md-7'
        }
    },
    mounted() {
        this.products.forEach((product, id) => {
            try {
                const variations = JSON.parse(product.productVariation);
                const firstChoice = variations.choices[0];
                const choices = product.choices;
                this.choicesSelected[id] = choices.find(choice => choice.id === firstChoice.externalId);
            } catch (e) {
                console.error(e);
            }
        });
        switch (this.columnFormat) {
            case '5/7':
                this.classColumnOne = 'col-md-5';
                this.classColumnTwo = 'col-md-7';
                break;
            case '6/6':
                this.classColumnOne = 'col-md-6';
                this.classColumnTwo = 'col-md-6';
                break;
            default:
                this.classColumnOne = 'col-lg-4 col-md-5';
                this.classColumnTwo = 'col-lg-8 col-md-7';
        }
    },
    methods: {
        chooseChoiceSelected(choiceId, index) {
            const id = index - 1;
            const choices = this.choicesSelected;
            const product = this.products[id];
            choices[id] = product.choices.find(choice => choice.id === choiceId);
            this.choicesSelected = choices;
        },
    }
}

</script>