import { defineStore } from 'pinia';
import {constants} from "@/constants";

export const useSessionStore = defineStore('session', {
  state: () => ({
    _session: null,
  }),
  getters: {
    session: (state) => {
      if (!state._session) {
        state._session = localStorage.getItem(constants.ECOM_SESSION) != null ?
          JSON.parse(localStorage.getItem(constants.ECOM_SESSION)) :
          null;
      }

      return state._session;
    }
  },
  actions: {
    setSession(session) {
      localStorage.setItem(constants.ECOM_SESSION, JSON.stringify(session));
      this._session = session;
    },
    emptySession() {
      localStorage.removeItem(constants.ECOM_SESSION);
      this._session = null;
    }
  }
});