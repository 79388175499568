<template>
    <div class="row justify-content-center">
        <div class="product-item col-lg-3 col-md-4 col-sm-6" v-for="product in products"
             v-bind:data-product-id="product.id">
            <div class="product-item-info card h-100">
                <ProductListImage :product="product" :isProductListFromParent="isProductListFromParent"/>
                <div class="product-item-details card-body">
                    <h3 class="product-item-name">
                        <a class="product-item-link" :href="product.url">
                            {{ product.name }}
                        </a>
                    </h3>
                    <Ratings v-if="showratingstar && !product.hideCustomerReviews" mode="skeepers_product__stars" :productCodeFromParent="product.code"></Ratings>
                    <p class="listing-long-name">
                        {{ product.longName }}
                    </p>
                    <a class="know-more" :href="product.url">
                        <span>{{ $t('labels.more') }}</span>
                    </a>
                </div>
                <div class="card-footer product-item-details-button">
                    <template v-if="getDefaultChoice(product)">
                        <Price :product="getDefaultChoice(product)" class="price-container"/>
                    </template>
                    <template v-if="showaddtocart && getDefaultChoice(product)">
                        <AddToCart :item="fillProductWithChoice(product)"/>
                    </template>
                    <a class="know-more" v-else :href="product.url">
                        <span>{{ $t('labels.more') }}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import AddToCart from '@/components/AddToCart.vue';
import Utils from "@/services/utils";
import Price from "@/components/productlist/subcomponents/Price.vue";
import Ratings from "@/components/product/Ratings.vue";
import ProductListImage from "@/components/productlist/subcomponents/Image.vue";

export default {
    name: 'ProductListSlider',
    props: {
        products: {
            type: Array,
            default: () => []
        },
        categoryid: {
            type: String,
            default: ''
        },
        showaddtocart: {
            type: Boolean,
            default: false
        },
        showratingstar: {
            type: Boolean,
            default: false
        },
        getDefaultChoice: {
            type: Function,
            required: true
        },
        fillProductWithChoice: {
            type: Function,
            required: true
        },
        isProductListFromParent: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ProductListImage,
        Price,
        AddToCart,
        Ratings
    },
    methods: {
        displayDuration(duration, startingPrice = false) {
            return Utils.displayDuration(duration, this.$i18n.locale, startingPrice);
        },
    }
}
</script>
