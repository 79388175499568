<template>
  <form action="" method="post" class="contact-form">

    <div v-if="loading" class="loading"></div>
    <div v-else-if="showBanner" class="alert alert-primary" >
      <p class="text-center m-0">
        {{ $t('labels.dataLinkedTo') }} <strong>{{ aspspEmail || order.billingEmail }}</strong> {{ $t('labels.haveBeenUsedToFillTheForm') }}
        {{ $t('labels.youWillBeDeliveredAt') }} <strong>{{ aspspFullAddress || fullAddress }}</strong>
      </p>
      <div class="text-right">
        <small><a href="#" @click.prevent="editAspspPrefillAction"
                  class="text-muted">{{ $t('labels.editMyAddress') }}</a></small>
      </div>
    </div>
    <template v-for="(field, fieldName) in contactFormConfig">
      <div v-show="showGroup(field, fieldName) || showMissingPrefilledField(field, fieldName)" :class="`field-group-${field.group} ${fieldName}`">
        <label v-if="field.type !== 'checkbox'" :for="fieldName" v-html="renderHTML(generateFieldLabel(field.label, field.variables, field.required))"></label>
        <template v-if="field.tag === 'input'">
          <input v-model.trim="order[fieldName]"
                 :type="field.type"
                 :name="fieldName"
                 :id="fieldName"
                 :placeholder="generateFieldLabel(field.placeholder)"
                 :required="field.required"
                 :checked="field.checked"
                 :class="'field field-type-' + field.type + ' field-' + fieldName"
                 @blur=" v$.computedOrder[fieldName] ? v$.computedOrder[fieldName].$touch() : null"
          />
          <span v-if="v$?.computedOrder[fieldName]?.$dirty && v$?.computedOrder[fieldName]?.$error" class="validation-error">{{ v$?.computedOrder[fieldName]?.$errors[0]?.$message }}</span>
        </template>
        <template v-else-if="field.tag === 'select'">
          <select v-model="order[fieldName]" :disabled="isFieldDisabled(fieldName)">
            <option v-for="option in generateOptions(field.keyOption, field.options)"
                    :name="fieldName"
                    :value="option.value"
                    :selected="order[fieldName] === option.value"
            >{{ option.label }}
            </option>
          </select>
        </template>
        <label v-if="field.type === 'checkbox'" :for="fieldName" v-html="renderHTML(generateFieldLabel(field.label, field.variables, field.required))"></label>
      </div>
    </template>

    <div v-if="!isEligibleToOneClick && (this.v$.computedOrder.$invalid || !this.computedOrder.acceptCgs || !this.computedOrder.acceptCgv)" class="alert alert-warning">
      {{ $t('messages.invalidContactForm') }}
    </div>
  </form>
</template>

<script>
import {useCartStore} from '@/store/cart.js';
import {useDraftStore} from '@/store/draft.js';
import {useOrderHistoryStore} from '@/store/orderHistory.js';
import {useAspspStore} from "@/store/aspsp";
import Orion from '@/services/orion.js';
import {useTrackingStore} from "@/store/tracking";
import Pg from "@/services/pg";
import {useVuelidate} from '@vuelidate/core'
import {required, email, sameAs, minLength, maxLength, helpers} from '@vuelidate/validators'
import CartService from "@/services/cart";
import Logger from "@/services/logger";
import optionsSelector from "@/i18n/optionsSelector";
import Utils from "@/services/utils";
import OneClickService from "@/services/payment/oneClick";
import { findCountryToBlock } from "@/services/validationForm/getCountryFromConfigSalesforce";
import postalCodes from "postal-codes-js";

export default {
  name: 'CheckoutContactForm',
  setup () {
    return { v$: useVuelidate() }
  },
  validations () {
    const emailValidation = helpers.withMessage(
        () => this.getValidationMessage("validation._default"), required
    );

    const sameAsBillingEmail = helpers.withMessage(
        () => this.getValidationMessage("validation.sameAsBillingEmail"), sameAs(this?.order.billingEmail)
    );

    const emailFormatValidation = helpers.withMessage(
        () => this.getValidationMessage("validation.emailInvalid"), email
    );
    const firstNameRequired = helpers.withMessage(
        () => this.getValidationMessage("validation._default"), required
    );
    const firstNameMinLength = helpers.withMessage(
        () => this.getValidationMessage("validation.firstNameMinLength"), minLength(2)
    );
    const firstNameMaxLength = helpers.withMessage(
        () => this.getValidationMessage("validation.firstNameMaxLength"), maxLength(40)
    );

    const lastNameRequired = helpers.withMessage(
        () => this.getValidationMessage("validation._default"), required
    );

    const phoneNumberRequired = helpers.withMessage(
        () => this.getValidationMessage("validation._default"), required
    );
    const lastNameMinLength = helpers.withMessage(
        () => this.getValidationMessage("validation.lastNameMinLength"), minLength(2)
    );
    const lastNameMaxLength = helpers.withMessage(
        () => this.getValidationMessage("validation.lastNameMaxLength"), maxLength(80)
    );

    const street3Required = helpers.withMessage(
        () => this.getValidationMessage("validation._default"), required
    );

    const street3MinLength = helpers.withMessage(
        () => this.getValidationMessage("validation.street3MinLength"), minLength(6)
    );

    const street3MaxLength = helpers.withMessage(
        () => this.getValidationMessage("validation.street3MaxLength"), maxLength(96)
    );

    const zipCodeRequired = helpers.withMessage(
        () => this.getValidationMessage("validation._default"), required
    );

    const cityRequired = helpers.withMessage(
        () => this.getValidationMessage("validation._default"), required
    );

    const cityMaxLength = helpers.withMessage(
        () => this.getValidationMessage("validation.cityMaxLength"), maxLength(40)
    );

    const phoneNumberValidation = helpers.withMessage(
        () => this.getValidationMessage("validation.phoneNumberInvalid"),
        helpers.regex(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/i)
    );

    const localRules = {
      computedOrder: {
        billingEmail: {emailValidation, emailFormatValidation},
        billingEmailConfirm: {emailValidation, emailFormatValidation, sameAsBillingEmail},
        billingFirstName: {firstNameRequired,firstNameMinLength,firstNameMaxLength},
        billingLastName: {lastNameRequired,lastNameMinLength,lastNameMaxLength},
        billingPhoneNumber: {maxLength: maxLength(20), regex: phoneNumberValidation},
        billingStreet3: { street3Required, street3MinLength, street3MaxLength },
        billingStreet1: {street3MaxLength},
        billingStreet2: {street3MaxLength},
        billingStreet4: {street3MaxLength},
        billingZipCode: {zipCodeRequired,isValidPostalCode: this.isValidPostalCode()},
        billingCity: {cityRequired, cityMaxLength},
        billingCountry: {required, maxLength: maxLength(80)}
      }
    };

    if (this.contactFormConfig.billingPhoneNumber?.required) {
      localRules.computedOrder.billingPhoneNumber = {phoneNumberRequired, ...localRules.computedOrder.billingPhoneNumber};
    }

    if (!this.order.sameAsBilling) {
      localRules.computedOrder.shippingFirstName = { firstNameRequired,firstNameMinLength,firstNameMaxLength };
      localRules.computedOrder.shippingLastName = { lastNameRequired,lastNameMinLength,lastNameMaxLength };
      localRules.computedOrder.shippingPhoneNumber = {maxLength: maxLength(20), regex: phoneNumberValidation};
      localRules.computedOrder.shippingStreet3 = { street3Required, street3MinLength, street3MaxLength };
      localRules.computedOrder.shippingStreet1 = { street3MaxLength };
      localRules.computedOrder.shippingStreet2 = { street3MaxLength };
      localRules.computedOrder.shippingStreet4 = { street3MaxLength };
      localRules.computedOrder.shippingZipCode = { zipCodeRequired,isValidPostalCode: this.isValidPostalCode() };
      localRules.computedOrder.shippingCity = { cityRequired, cityMaxLength };
      localRules.computedOrder.shippingCountry = { required, maxLength: maxLength(80) };

      if (this.contactFormConfig.shippingPhoneNumber?.required) {
        localRules.computedOrder.shippingPhoneNumber = {phoneNumberRequired, ...localRules.computedOrder.shippingPhoneNumber};
      }
    }

    return localRules;
  },
  data() {
    return {
      order: {}, // order data
      contactFormConfig: {}, // form config
      paysValidate: {},
      countryAutoSetBilling: false,
      countryAutoSetShipping: false,
      originalOrderPrefilled: {}
    }
  },
  computed: {
    computedOrder() {
      /**
       * https://github.com/vuejs/vue/issues/2164#issuecomment-432872718
       * must be a new object to trigger vue reactivity (and get proper before / after state)
       */
      return Object.assign({}, this.order);
    },
    aspspPrefillData() {
      return useAspspStore().prefillData;
    },
    showBanner() {
      return (useDraftStore().isEligibleToOneClick && window.globalConfig.authorized1click) || (useAspspStore().isEligible && !useAspspStore().aspspPrefillEditable);
    },
    aspspEmail() {
      return useAspspStore().email;
    },
    aspspFullAddress() {
      return useAspspStore().aspspFullAdress;
    },
    loading() {
      return useDraftStore().isPrefillLoading;
    },
    isEligibleToOneClick() {
      return useDraftStore().isEligibleToOneClick;
    },
    has1ClickOptOut() {
      return useDraftStore().has1ClickOptOut;
    },
    fullAddress() {
      return useDraftStore().fullAddress;
    },
    selectedPaymentMethod() {
      return useDraftStore().selectedPaymentMethod;
    }
  },
  watch: {
    'order.acceptCgv': {
      handler(newValue, oldValue) {
        useDraftStore().approveCgv(newValue);
      }
    },
    'order.acceptCgs': {
      handler(newValue, oldValue) {
        useDraftStore().approveCgs(newValue);
      }
    },
    'order.shippingCountry': {
      handler(newValue, oldValue) {
        useDraftStore().selectCountryCode(newValue);
        useCartStore().cart.shippingCountry = newValue;
        CartService.updateCart();
        this.processInfo("shipping");
      }
    },
    'selectedPaymentMethod': {
      handler(newValue, oldValue) {
        this.order.paymentType = newValue; // trigger update of order and send it to orion
      }
    },
    'computedOrder': {
      handler(newValue, oldValue) {
        useDraftStore().updateOrder(newValue);

        if (useDraftStore().isSendingDraftOrder) {
          // don't send previous event if we need to send another one
          window.clearTimeout(useDraftStore().isSendingDraftOrder);
        }

        if (this.order.sameAsBilling && Orion.needCopyBillingIntoShipping(this.order)) {
          Orion.copyBillingIntoShipping(this.order);
        }
        if (this.order.acceptCgv && this.order.acceptCgs && this.v$.computedOrder.$invalid === false && this.v$.computedOrder.$silentErrors.length === 0) {
          useDraftStore().formValid(true);
        } else {
          useDraftStore().formValid(false);
        }

        if (
            (
                (oldValue.billingEmail !== newValue.billingEmail || oldValue.billingEmailConfirm !== newValue.billingEmailConfirm) &&
                this.order.billingEmail === this.order.billingEmailConfirm
            ) || useDraftStore().isFormValid
        ) {
          // If either billingEmail or billingEmailConfirm has just  been edited and are equals
          // Or if the form is valid
          useDraftStore().sendingDraftOrder(window.setTimeout(async () => {
            console.log('sending draft order to orion');

            const preparedOrder = Orion.prepareOrder(useCartStore().cart, this.order, {orderType: 'initial'});
            useDraftStore().updateOrder(preparedOrder);
            console.log(preparedOrder);

            const minifiedCart = CartService.minifyCart();

            if (!useDraftStore().isEligibleToOneClick) {
              await Orion.sendOrder(minifiedCart, preparedOrder, window.globalConfig.domainId);
            }

            useOrderHistoryStore().addOrderToHistory(useCartStore().cart, preparedOrder);

            useDraftStore().sendingDraftOrder(false);
            useDraftStore().draftOrderSent(true);
          }, window.contactFormConfig.sendingDraftDelay));
        }
      },
      deep: true
    },
    aspspPrefillData: {
      handler(newValue, oldValue) {
        if (!newValue) {
          return;
        }

        this.prefill({
          billingEmail: newValue.EMAIL,
          billingGender: newValue.GENDER || null,
          billingFirstName: newValue.FIRSTNAME,
          billingLastName: newValue.LASTNAME,
          billingPhoneNumber: newValue.BILLINGPHONE__C,
          billingStreet3: newValue.BILLINGSTREET3__C,
          billingStreet4: newValue.BILLINGSTREET4__C,
          billingStreet1: newValue.BILLINGSTREET1__C,
          billingStreet2: newValue.BILLINGSTREET2__C,
          billingZipCode: newValue.BILLINGZIPCODE__C,
          billingCity: newValue.BILLINGCITY__C,
          billingCountry: newValue.BILLINGCOUNTRY__C
        });
      },
      deep: true
    },
    has1ClickOptOut: {
      handler(newValue) {
        this.order.has1ClickOptOut = newValue;
      }
    },
    "order.billingZipCode"(newZipCode) {
      this.processInfo("billing");
    },
    "order.billingCountry"(newCountry) {
      this.processInfo("billing");
    },
    "order.shippingZipCode"(newZipCode) {
      this.processInfo("shipping");
    },
    'order.sameAsBilling': {
      handler() {
        // Retrieves an array of all billing and shipping form fields
        const properties = this.v$.$silentErrors.map(error => error.$property)
        // Retrieves an object with billing names as keys and shipping names as values
        const mapping = this.createFieldMapping(properties)
        this.syncBillingAndShippingErrors(this.v$, mapping)
      }
    },
  },
  mounted() {
    this.contactFormConfig = window.contactFormConfig.fields;
    try {
      this.paysValidate = JSON.parse(window.globalConfig.mappingCountryZipcode); // get config saleforce zip_code ISO
    }
    catch (e) {
      console.error('Mapping country zipCode no found : ', e)
    }

    for (const fieldName of Object.keys(this.contactFormConfig)) {
      const config = this.contactFormConfig[fieldName];
      if (config.value !== undefined) {
        this.order[fieldName] = config.value;
      }
    }

    const regexIdentifier = /^[a-zA-Z0-9]*$/;
    if (useTrackingStore().urlParams.sfId && regexIdentifier.test(useTrackingStore().urlParams.sfId)) {
      useDraftStore().prefillLoading(true);

      Pg.getPrefillData(
          useTrackingStore().urlParams.sfId,
          window.globalConfig.affiliateId
      ).then((data) => this.prefill(data))
          .catch((error) => {
            console.error(error);
            Logger.error('Error getting pgsf informations', error);
          });
    }
  },
  methods: {
    getValidationMessage(key) {
      this.$i18n.locale = window.globalConfig.lang || window.navigator.language.split('-')[0];
      return this.$i18n.t(key);
    },
    createFieldMapping(properties) {
      return properties.reduce((acc, prop) => {
        if (prop.startsWith('billing')) {
          acc[prop] = 'shipping' + prop.slice(7)
        }
        return acc
      }, {})
    },
    /**
     * Synchronizes the two forms to have the same errors when the checkbox is unchecked @validator
     * @param {Object} validator - The vuelidate validator object
     * @param {Object} fieldMap - The mapping between billing and shipping fields
     * @returns {void}
     * */
    syncBillingAndShippingErrors(validator, fieldMap) {
      const errors = validator.$errors
      for (const error of errors) {
        const field = Object.keys(fieldMap).find(billingField => error.$property.includes(billingField))
        if (field) {
          validator.computedOrder[fieldMap[field]]?.$touch()
        }
      }
    },
    isValidPostalCode() {
      const validateZipCode = (country, zipCode) => {
        return (country === "ICA" &&
            (zipCode.startsWith("35") || zipCode.startsWith("38")) &&
            /^\d{5}$/.test(zipCode)
        );
      };

      if (validateZipCode(this.order.billingCountry, this.order.billingZipCode) ||
          validateZipCode(this.order.shippingCountry, this.order.shippingZipCode)) {
        return true;
      }

      return helpers.withMessage(() => this.getValidationMessage("validation.zipCodeInvalid"),
          helpers.withParams({type: "isValidPostalCode"}, (value) => {
            if (!value) return true;

            const isValid =
                postalCodes.validate(this.order.billingCountry, value) ||
                postalCodes.validate(this.order.shippingCountry, value);

            return isValid === true ? isValid : false;
          })
      );
    },
    isFieldDisabled(fieldName) {
      const fieldToPropertyMapping = {
        billingCountry: 'countryAutoSetBilling',
        shippingCountry: 'countryAutoSetShipping'
      };

      return this[fieldToPropertyMapping[fieldName]] || false;
    },
    processInfo(type) {
      const zipCode = this.order[`${type}ZipCode`];
      const country = this.order[`${type}Country`];

      // Expected length for zip codes in the countries concerned
      const EXPECTED_ZIP_CODE_LENGTH = 5;

            if (zipCode?.length === EXPECTED_ZIP_CODE_LENGTH) {
                let countryBlocked = findCountryToBlock(zipCode,country,this.paysValidate);
                if (countryBlocked) {
                    if (countryBlocked === ' blocked') {
                        this.order[`${type}Country`] = null;
                        this[`countryAutoSet${Utils.capitalize(type)}`] = true;
                    } else {
                        this.order[`${type}Country`] = countryBlocked;
                        this[`countryAutoSet${Utils.capitalize(type)}`] = true;
                    }
                }
            }
            if (!zipCode || zipCode.length < EXPECTED_ZIP_CODE_LENGTH) {
                this[`countryAutoSet${Utils.capitalize(type)}`] = false;
            }
        },
        editAspspPrefillAction() {
            this.order.acceptCgs = false;
            this.order.acceptCgv = false;
            useDraftStore().eligibleToOneClick(false);
            useAspspStore().updateAspspPrefillEditable(true);
        },
        showGroup(field, fieldName) {
          const group = field.group
          if(this.isEligibleToOneClick && group === 'cgv') {
              return false;
            }
            if (this.showBanner && (group === 'billing' || group === 'shipping')) {
                return false;
            }
            if (group === 'shipping' && this.order.sameAsBilling) {
                return false;
            }

      return true;
    },
    showMissingPrefilledField(field, fieldName) {
      if (field.group === 'billing' || (field.group === 'shipping' && !this.order.sameAsBilling)) {
        const showMissingPrefilledField = field.required && (!this.originalOrderPrefilled[fieldName] || this.originalOrderPrefilled[fieldName] === '');

        if (showMissingPrefilledField) {
          this.v$.computedOrder[fieldName].$touch();
          return true;
        }
      }

      return false;
    },
    prefill(data) {
      let sameAsBilling = true;
      if (data.shippingStreet3) {
        sameAsBilling = data.shippingStreet3 === data.billingStreet3 && data.shippingZipCode === data.billingZipCode && data.shippingCity === data.billingCity && data.shippingCountry === data.billingCountry && data.shippingStreet1 === data.billingStreet1 && data.shippingStreet2 === data.billingStreet2 && data.shippingStreet4 === data.billingStreet4;
      }

      const acceptConditionGeneral = OneClickService.prefillOneClick(data);
      this.order = {
        ...this.order,
        ...acceptConditionGeneral
      };

      this.order.billingEmail = data.billingEmail;
      this.order.billingEmailConfirm = data.billingEmail;
      this.order.billingGender = data.billingGender;
      this.order.billingFirstName = data.billingFirstName;
      this.order.billingLastName = data.billingLastName;
      this.order.billingPhoneNumber = data.billingPhoneNumber;
      this.order.billingStreet3 = data.billingStreet3;
      this.order.billingStreet4 = data.billingStreet4;
      this.order.billingStreet1 = data.billingStreet1;
      this.order.billingStreet2 = data.billingStreet2;
      this.order.billingZipCode = data.billingZipCode;
      this.order.billingCity = data.billingCity;
      this.order.billingCountry = data.billingCountry;
      this.order.shippingCity = data.shippingCity;
      this.order.shippingCountry = data.shippingCountry;
      this.order.shippingPhoneNumber = data.billingPhoneNumber;
      this.order.shippingStreet1 = data.shippingStreet1;
      this.order.shippingStreet2 = data.shippingStreet2;
      this.order.shippingStreet3 = data.shippingStreet3;
      this.order.shippingStreet4 = data.shippingStreet4;
      this.order.shippingZipCode = data.shippingZipCode;
      this.order.sameAsBilling = sameAsBilling;

      this.originalOrderPrefilled = {
        ...this.order
      }

      useDraftStore().prefillLoading(false);
    },
    translateError(error) {
      let message = error.$message;

      if (['minLength', 'maxLength'].includes(error.$validator)) {
        for (const paramKey in error.$params) {
          message = message.replace(error.$params[paramKey], `{${paramKey}}`)
        }
      }
      return this.$t(`errors.${message}`, error.$params);
    },
    generateFieldLabel(label, variables, required = false) {
      if (!label) {
        return;
      }
      const labelSplit = label.split(' ');
      const labelRequired = required ? '%3Cspan%20class%3D%22required%22%3E*%3C%2Fspan%3E' : ''; // add * on the label field
      return labelSplit.map(word => {
        if (word.startsWith('$')) {
          return this.$t(`contactForm.${word.substring(1)}`);
        } else if (word.startsWith('#')) {
          return variables[word.substring(1)];
        } else {
          return word;
        }
      }).join(' ') + ' ' + labelRequired;
    },
    generateOptions(key, oldOptions) {
      let options = []
      const language = window.globalConfig.lang
      for (const option in optionsSelector[key]) {
        options.push({
          label: optionsSelector[key][option][language],
          value: option
        })
      }

      if (key === 'countries') {
        if (window.globalConfig.countriesExcluded) {
          const countriesExcluded = window.globalConfig.countriesExcluded.split(';');
          options = options.filter(option => !countriesExcluded.includes(option.value))
        }
        options = Utils.sortAlphabetically(options, 'label')
      }

      // @Todo Delete after one or two week after this deployment
      if (!options.length) {
        return oldOptions
      }

      return options
    }
  }
}
</script>
