<template>
    <div v-if="shortDescription" v-html="renderHTML(shortDescription)" class="shortDescription"></div>
</template>

<script>
export default {
    name: 'ProductShortDescription',
    props: {
        product: {
            type: Object
        }
    },
    data() {
        return {
            shortDescription: null,
        }
    },
    mounted() {
        this.shortDescription = this.product?.shortDescription || window.productDetails.shortDescription;
    }
}
</script>