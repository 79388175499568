import {defineStore} from 'pinia';
import {constants} from "@/constants";
import Navigation from "@/services/navigation";

export const useTrackingStore = defineStore('tracking', {
  state: () => ({
    _urlParams: null,
  }),
  getters: {
    urlParams: (state) => {
      if (!state._urlParams) {
        const urlParamsBeforeMerge = localStorage.getItem(constants.URL_PARAMS) != null ?
          JSON.parse(localStorage.getItem(constants.URL_PARAMS)) :
          {};

        const queryParams = Navigation.getQueryParams();

        let testLevel = queryParams.get('testLevel');
        if (queryParams.get('isBat') && (
          queryParams.get('isBat') === '1' ||
          queryParams.get('isBat') === 'true'
        )
        ) {
          testLevel = 'salesforce_only';
        }

        if (testLevel !== null) {
          queryParams.set('testLevel', testLevel);
        }

        for (const [key, value] of queryParams) {
          if (value === 'null') {
            delete urlParamsBeforeMerge[key];
          } else {
            urlParamsBeforeMerge[key] = value;
          }
        }

        state._urlParams = urlParamsBeforeMerge;
        localStorage.setItem(constants.URL_PARAMS, JSON.stringify(urlParamsBeforeMerge));
      }

      return state._urlParams;
    }
  },
  actions: {
    mergeTrackingData(urlParams) {
      const urlParamsBeforeMerge = this.urlParams;
      for (const [key, value] of Object.entries(urlParams)) {
        if (value === 'null') {
          delete urlParamsBeforeMerge[key];
        } else {
          urlParamsBeforeMerge[key] = value;
        }
      }
      localStorage.setItem(constants.URL_PARAMS, JSON.stringify(urlParamsBeforeMerge));
    }
  }
});