<template>
    <div class="choice-selector-wrapper">
        <div class="choice-selector">
            <template v-for="element in elementsVariation">
                <div :id="`${element}-${index}`" :class="`variation ${element}`" v-if="element !== 'externalId'">
                    <span class="element-title" v-html="renderHTML(config[element].label)"></span>
                    <template v-for="variation in variations[element]">
                        <label :class="`variation-container${disabledInputRadio(element, variation) ? ' input-disabled' : ''}`" :for="(`${element}-${variation}-${index}`).replace(/ /g, '-').replace(/\./g, '').toLowerCase()">
                            <span class="variation-name">{{ variation }}</span>
                            <input :id="(`${element}-${variation}-${index}`).replace(/ /g, '-').replace(/\./g, '').toLowerCase()" type="radio" :disabled="disabledInputRadio(element, variation)" :name="`${element}-${index}`" :checked="values[element] === variation" :value="variation" v-model="values[element]">
                            <span :class="`checkmark ${typeof variation === 'string' ? variation.replace(/ /g, '-').replace(/\./g, '').toLowerCase() : variation}`"></span>
                        </label>
                    </template>
                </div>
            </template>
        </div>
        <div class="choice-selected" v-if="choiceSelected">
            <div class="choiceSelectedName">
                <span class="labelChoiceName">{{ $t('labels.choiceName')}}</span>
                <span class="choiceName">{{ choiceName }}</span>
            </div>
            <div class="choiceSelectedPrice">
                <span class="labelChoicePrice">{{ $t('labels.choicePrice')}}</span>
                <span class="choicePrice">{{ choicePrice }}</span>
                <span class="currency">&euro;</span>
            </div>
        </div>
    </div>
</template>

<script>

import {useDraftStore} from "@/store/draft";
import Price from "@/services/price";

export default {
    name: 'ProductDirectBuy',
    props: {
        index: {
            type: Number,
            default: 0
        },
        isFromList: {
            type: Boolean,
            default: false
        },
        chooseChoiceSelected: {
            type: Function,
            default: () => {}
        },
        product: {
            type: Object
        }
    },
    data() {
        return {
            choices: [],
            choicestVariation: [],
            config: {},
            elementsVariation: [],
            variations: {},
            values: {}
        }
    },
    computed: {
        choiceSelected() {
            return this.choices.find(choice => choice.id === this.choiceVariant.externalId);
        },
        choiceName() {
            return this.choiceSelected.name;
        },
        choicePrice() {
            return Price.format(this.choiceSelected.defaultPrice);
        },
        choiceVariant() {
            return this.choicestVariation.find(variation => {
                return Object.keys(this.values).every(key => {
                    return key === 'externalId' ? true : variation[key] === this.values[key];
                });
            });
        },
        storeSelectedChoice() {
            return !this.isFromList ? useDraftStore().selectedChoice : null;
        }
    },
    mounted() {
        try {
            const product = this.product || window.productDetails;
            this.choices = product.choices;
            const productVariation = JSON.parse(product.productVariation);
            this.choicestVariation = productVariation.choices;
            this.config = productVariation.config;
            this.choicestVariation.forEach(variation => {
                const variationElements = Object.keys(variation);
                variationElements.forEach(element => {
                    if (!this.variations[element]) {
                        this.elementsVariation.push(element);
                        this.variations[element] = [];
                        this.values[element] = variation[element];
                    }
                    if (!this.variations[element].includes(variation[element])) {
                        this.variations[element].push(variation[element]);
                    }
                });
            })
        } catch (error) {
            console.error(error);
            this.choicestVariation = [];
            this.config = {};
            this.elementsVariation = [];
            this.variations = {}
        }
    },
    methods: {
        disabledInputRadio(element, value) {
            const fieldCanBeDisabled = this.config[element].fieldCanBeDisabled;
            if (!fieldCanBeDisabled) {
                return false;
            }
            const choices = this.choicestVariation.filter(variation => {
                return Object.keys(this.values).every(key => {
                    return key === 'externalId' || key === element ? true : variation[key] === this.values[key];
                });
            });
            for (const [index, choice] of choices.entries()) {
                if (!choice.externalId && choice[element] === value) {
                    if (this.choiceVariant === choice) {
                        const choiceSelected = index === 0 ? choices[1] : choices[0];
                        this.values = {...choiceSelected};
                    }
                    return true;
                }
            }
            return false;
        }
    },
    watch: {
        values: {
            handler: function () {
                const newVal = this.choiceSelected ? this.choiceSelected.id : null;
                if (this.storeSelectedChoice !== newVal || this.isFromList) {
                    !this.isFromList ? useDraftStore().selectChoice(newVal) : this.chooseChoiceSelected(newVal, this.index);
                }
            },
            deep: true
        }
    }
}
</script>