export const constants = {
  ECOM_CART: 'shop-cart',
  ECOM_ORDER_HISTORY: 'shop-order-history',
  PAYMENT_METHOD_SEPA: 'sepa',
  PAYMENT_METHOD_BANK_CARD: 'bank_card',
  PARAM_PLUS: 'shop-param-plus',
  URL_PARAMS: 'shop-url-params',
  AUTHORIZATION_STATUS_TIMEOUT: 1000 * 60 * 3, // 3 minutes
  ECOM_SESSION: 'shop-session',
  CONFIG_GRAPES: 'config-grapes',
  HAS_1_CLICK_OPTOUT: 'has-1-click-optout',
  SELECTED_CHOICES_TYPE: 'selected-choices-type',
  DEFAULT_CHOICES_MODE: 'default',
  ALTERNATIVE_CHOICES_MODE: 'alternative'
};

