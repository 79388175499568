<template></template>
<script>
export default {
    name: 'Seo',
    props: ['pagetype', 'title', 'description'],
    data() {
        return {
            metaTitle: '',
            metaDescription: '',
            favicon: ''
        }
    },
    mounted() {
        switch (this.pagetype) {
            case 'home':
                this.metaTitle = this.title ? this.title : '';
                this.metaDescription = this.description ? this.description : '';
                document.body.classList.add('home');
                break;
            case 'category':
                this.metaTitle = `${window.category.name}`;
                this.metaDescription = `${window.category.name}`;
                document.body.classList.add(window.category.slug);
                break;
            case 'product':
                this.metaTitle = window.productDetails.featuredProductName || `${window.productDetails.name}`;
                this.metaDescription = `${window.productDetails.description ? decodeURIComponent(window.productDetails.description).replace(/(<([^>]+)>)/gi, "") : ''}`;
                this.addProductSchema();
                this.buildProductMetas();
                this.buildCanonical();
                document.body.classList.add(window.productDetails.slug);
                break;
            default:
                this.metaTitle = this.title ? this.title : '';
                this.metaDescription = this.description ? this.description : '';
                break;
        }

        if (window.globalConfig.favicon) {
            this.favicon = window.globalConfig.favicon;
        }

    },
    watch: {
        metaTitle() {
            document.title = `${this.metaTitle} | ${window.globalConfig.siteName}`;
        },
        metaDescription() {
            const metaDescription = document.querySelector('meta[name="description"]');
            metaDescription.content = this.metaDescription;
        },
        favicon() {
            const favicon = document.querySelector('link[rel="icon"]');
            if (favicon) {
                favicon.setAttribute('type', 'image/x-icon');
                favicon.setAttribute('href', window.globalConfig.favicon);
            } else {
                const favicon = document.createElement('link');
                favicon.setAttribute('rel', 'icon');
                favicon.setAttribute('type', 'image/x-icon');
                favicon.setAttribute('href', window.globalConfig.favicon);
                document.head.appendChild(favicon);
            }

            const shortcutIcon = document.querySelector('link[rel="shortcut icon"]');
            if (shortcutIcon) {
                shortcutIcon.setAttribute('type', 'image/x-icon');
                shortcutIcon.setAttribute('href', window.globalConfig.favicon);
            } else {
                const shortcutIcon = document.createElement('link');
                shortcutIcon.setAttribute('rel', 'shortcut icon');
                shortcutIcon.setAttribute('type', 'image/x-icon');
                shortcutIcon.setAttribute('href', window.globalConfig.favicon);
                document.head.appendChild(shortcutIcon);
            }
        }
    },
    methods: {
        buildCanonical() {
            const link = document.querySelector('link[rel="canonical"]');
            if (link) {
                link.href = `${window.globalConfig.siteBaseUrl}/${window.productDetails.slug}`;
            } else {
                const canonical = document.createElement('link');
                canonical.setAttribute('rel', 'canonical');
                canonical.setAttribute('href', `${window.globalConfig.siteBaseUrl}/${window.productDetails.slug}`);
                document.head.appendChild(canonical);
            }
        },
        addProductSchema() {
            const body = document.getElementsByTagName('body')[0];
            body.setAttribute('itemtype', 'http://schema.org/Product');
            body.setAttribute('itemscope', 'itemscope');
        },
        buildMeta(name, metaAttribute, content) {
            let metaTagExists = null;
            metaTagExists = document.querySelector(`meta[${metaAttribute}="${name}"]`);

            if (metaTagExists) {
                metaTagExists.setAttribute('content', content);
            } else {
                const meta = document.createElement('meta');
                meta.setAttribute(metaAttribute, name);
                meta.setAttribute('content', content);
                document.head.appendChild(meta);
            }
        },
        buildProductMetas() {
            this.buildMeta('title', 'name', window.productDetails.featuredProductName || window.productDetails.name);
            this.buildMeta('description', 'name', window.productDetails.featuredProductName || window.productDetails.name);
            this.buildMeta('keywords', 'name', window.productDetails.featuredProductName || window.productDetails.name);
            this.buildMeta('robots', 'name', 'INDEX,FOLLOW');
            this.buildMeta('og:type', 'property', 'product');
            this.buildMeta('og:title', 'property', window.productDetails.featuredProductName || window.productDetails.name);

            const images = window.productDetails.images;
            if (images && images.length > 0 && images.filter(i => i.type === 'main').length > 0) {
                this.buildMeta('og:image', 'property', images.filter(i => i.type === 'main')[0].src);
            }

            this.buildMeta('og:description', 'property', window.productDetails.featuredProductName || window.productDetails.name);
            this.buildMeta('og:url', 'property', `${window.globalConfig.siteBaseUrl}/${window.productDetails.slug}`);

            let minPrice = null;
            for (const choice of window.productDetails.choices) {
                if (!minPrice || choice.unitPrice < minPrice) {
                    minPrice = choice.unitPrice;
                }
            }

            this.buildMeta('product:price:amount', 'property', minPrice / 100);
            this.buildMeta('product:price:currency', 'property', 'EUR');
            this.buildMeta('productID', 'itemprop', window.productDetails.id);
        }
    }
}
</script>