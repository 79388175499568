import {defineStore} from 'pinia'
import {constants} from '@/constants';

export const useOrderHistoryStore = defineStore('orderHistory', {
  state: () => ({
    _orderHistory: [],
  }),
  getters: {
    orderHistory: (state) => {
      if (state._orderHistory.length === 0) {
        return localStorage.getItem(constants.ECOM_ORDER_HISTORY) != null ?
          JSON.parse(localStorage.getItem(constants.ECOM_ORDER_HISTORY)) :
          [];
      }

      return state._orderHistory;
    },
  },
  actions: {
    addOrderToHistory(cart, order) {
      const existingHistoryItem = this.orderHistory.find((i) => i.order.externalOrderId === order.externalOrderId);

      if (!existingHistoryItem) {
        this.orderHistory.push({order, cart});
      } else {
        Object.assign(existingHistoryItem, {order, cart});
      }

      this.orderHistory.splice(0, this.orderHistory.length-10); // limiting history to the 10 last orders

      localStorage.setItem(constants.ECOM_ORDER_HISTORY, JSON.stringify(this.orderHistory));
    },
  },
})
