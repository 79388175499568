import { defineStore } from 'pinia';
import payment from "@/components/waiting/Payment.vue"

export const useAspspStore = defineStore('aspsp', {
  state: () => ({
    _eligible: false,
    _doubleOptin: false,
    _paymentIdentifier: null,
    _paymentAccountIdentifier: null,
    _mandateIdentifier: null,
    _email: null,
    _prefillData: null,
    _aspspFullAdress: null,
    _aspspPrefillEditable : false,
    _pciToken: null,
    _holderName: null,
    _aspspEligibleBankCard: false,
    _aspspEligibleSepa: false,
    _aspspPaymentInformations: {
      sepa: null,
      bank_card: null
    }
  }),
  getters: {
    isEligible: (state) => {
      return state._eligible;
    },
    isDoubleOptin: (state) => {
      return state._doubleOptin;
    },
    paymentIdentifier: (state) => {
      return state._paymentIdentifier;
    },
    paymentAccountIdentifier: (state) => {
      return state._paymentAccountIdentifier;
    },
    mandateIdentifier: (state) => {
      return state._mandateIdentifier;
    },
    email: (state) => {
      return state._email;
    },
    prefillData: (state) => {
      return state._prefillData;
    },
    aspspFullAdress: (state) => {
      return state._aspspFullAdress;
    },
    aspspPrefillEditable: (state) => {
      return state._aspspPrefillEditable;
    },
    pciToken: (state) => {
      return state._pciToken;
    },
    holderName: (state) => {
      return state._holderName;
    },
    isAspspEligibleBankCard: (state) => {
      return state._aspspEligibleBankCard;
    },
    isAspspEligibleSepa: (state) => {
      return state._aspspEligibleSepa;
    },
    aspspPaymentInformations: (state) => {
      return state._aspspPaymentInformations;
    }
  },
  actions: {
    eligible(eligibility) {
      this._eligible = eligibility;
    },
    doubleOptin(doubleOptin) {
      this._doubleOptin = doubleOptin;
    },
    updatePaymentIdentifier(paymentIdentifier) {
      this._paymentIdentifier = paymentIdentifier;
    },
    updatePaymentAccountIdentifier(paymentAccountIdentifier) {
      this._paymentAccountIdentifier = paymentAccountIdentifier;
    },
    updateMandateIdentifier(mandateIdentifier) {
      this._mandateIdentifier = mandateIdentifier;
    },
    updateEmail(email) {
      this._email = email;
    },
    updatePrefillData(prefillData) {
      this._prefillData = prefillData;
    },
    updatAspspFullAdress(aspspFullAdress) {
      this._aspspFullAdress = aspspFullAdress;
    },
    updateAspspPrefillEditable(aspspPrefillEditable) {
      this._aspspPrefillEditable = aspspPrefillEditable;
    },
    updatePciToken(pciToken) {
      this._pciToken = pciToken;
    },
    updateHolderName(holderName) {
      this._holderName = holderName;
    },
    updateAspspEligibleBankCard(aspspEligibleBankCard) {
      this._aspspEligibleBankCard = aspspEligibleBankCard;
    },
    updateAspspEligibleSepa(aspspEligibleSepa) {
      this._aspspEligibleSepa = aspspEligibleSepa;
    },
    updateAspspPaymentInformations(aspspPaymentInformations) {
      this._aspspPaymentInformations = aspspPaymentInformations;
    },
    updateAspspData(paymentMethod) {
      if (!this.isEligible || !paymentMethod || paymentMethod === '') return;

      const aspspPaymentInformations = this.aspspPaymentInformations;

      this.updatePaymentAccountIdentifier(aspspPaymentInformations[paymentMethod].paymentAccountIdentifier);
      this.updatePaymentIdentifier(aspspPaymentInformations[paymentMethod].paymentIdentifier);
      this.updateMandateIdentifier(aspspPaymentInformations[paymentMethod].mandateIdentifier);
      this.doubleOptin(aspspPaymentInformations[paymentMethod].doubleOptin);
      this.updatePciToken(aspspPaymentInformations[paymentMethod].pciToken);
      this.updateHolderName(aspspPaymentInformations[paymentMethod].holderName);
    }
  }
});