import sha1 from 'js-sha1';
import { v4 as uuidv4 } from 'uuid';
import translations from '@/i18n/messages.json';

export default class Utils {
  static conditioningValueClassPrefix = 'conditioning-value';
  static conditionedItemClassPrefix = 'conditional-item';

  static generateUID() {
    return sha1(uuidv4() + uuidv4() + (new Date()).getTime());
  }

  static displayDuration(duration, lang, startingPrice = false) {
    if (!duration) return '';

    const t = translations[lang].labels;
    duration = parseInt(duration);

    if (duration % 12 === 0) { // Round years
      const years = duration / 12;
      if (years === 1) {
        return startingPrice ? ' ' + t.theFirstYear : ' ' + t.year;
      } else {
        return startingPrice ? ` ${t.thePlural} ${years} ${t.firstYears}` : `${years} ${t.years}`;
      }
    } else { // Months
      if (duration === 1) {
        return startingPrice ? ' ' + t.theFirstMonth : ' ' + t.month;
      } else {
        return startingPrice ? ` ${t.thePlural} ${duration} ${t.firstMonths}` : `${duration} ${t.months}`;
      }
    }
  }

  static handleConditionedContent() {
    const conditionedValueClassPrefix = this.conditioningValueClassPrefix + '-';
    const conditionedItemClassPrefix = this.conditionedItemClassPrefix + '-';
    const conditionedItems = document.querySelectorAll(`[class*="${conditionedItemClassPrefix}"]`);

    // Make disappear every conditionedItems if the corresponding conditionedValue has empty content
    for (const conditionedItem of conditionedItems) {
      const conditionedItemClasses = conditionedItem.className.split(' ');
      for (const conditionedItemClass of conditionedItemClasses) {
        if (conditionedItemClass.indexOf(conditionedItemClassPrefix) !== -1) {
          const id = conditionedItemClass.replace(conditionedItemClassPrefix, '');
          const conditionedValue = document.querySelector(`.${conditionedValueClassPrefix}${id}`);
          const regex1 = /^\x3C!---->/; // VueJS replaces empty components by this string.
          const regex2 = /^\x3C!--v-if-->/; // VueJS replaces components hidden by an if by this string.

          if (conditionedValue && (conditionedValue.innerHTML === '' || regex1.test(conditionedValue.innerHTML) || regex2.test(conditionedValue.innerHTML))) {
            conditionedItem.style.display = 'none';
          } else {
            conditionedItem.style.display = 'block';
          }
        }
      }
    }
  }

  static addConditionedContentListener() {
    const observer = new MutationObserver((mutations) => {
      let callHandleConditionedContent = false;

      mutations.forEach((mutation) => {
        if (mutation.type !== 'childList' || callHandleConditionedContent) return;

        const rootItemClasses = mutation.target.classList;
        const addedNodesClasses = mutation.addedNodes.length !== 0 ? this.getClassesFromNode(mutation.addedNodes) : [];
        const removedNodesClasses = mutation.removedNodes.length !== 0 ? this.getClassesFromNode(mutation.removedNodes) : [];
        const allItemClasses = [...rootItemClasses, ...addedNodesClasses, ...removedNodesClasses];

        callHandleConditionedContent = this.checkClassesForConditionalContent(allItemClasses);
      });

      if (callHandleConditionedContent) {
        this.handleConditionedContent();
      }
    });

    observer.observe(document.querySelector('body'), {
      attributes: false,
      childList: true,
      subtree: true
    });
  }

  static checkClassesForConditionalContent(itemClasses) {
    let result = false;

    itemClasses.forEach((itemClass) => {
      if (itemClass.startsWith(this.conditioningValueClassPrefix) || itemClass.startsWith(this.conditionedItemClassPrefix)) {
        result = true;
      }
    });

    return result;
  }

  static getClassesFromNode(nodes) {
    const classes = [];
    nodes.forEach((node) => {
      if (node.classList) {
        node.classList.forEach((nodeClass) => {
          classes.push(nodeClass);
        });
      }
    });

    return classes;
  }

  /**
   * Sort an array of objects alphabetically for a specific key.
   * @param array
   * @param key
   */
  static sortAlphabetically(array, key) {
    return array.sort((a, b) => {
      return a[key].localeCompare(b[key], 'und', { sensitivity: 'base' });
    })
  }

  static getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  }

  static deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
  }

  static insertScriptAvisVerifies() {
    if (window.globalConfig.scriptAvisVerifies && (window.productDetails || window.productList)) {
      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = window.globalConfig.scriptAvisVerifies;
      script.async = true;
      head.appendChild(script);
    }
  }

  static capitalize = (str) => {
      if(!str) return str;
      return str.charAt(0).toUpperCase() + str.slice(1);
  }
}