<template>
    <div v-if="(!cartMode && isLoading === false) || (cartMode && products.length)">
        <div :class="mode + (count ? ' count-' + count : '')" :data-count="count">
            <template v-if="mode === 'products-slider'">
                <Slider :products="products"
                        :categoryid="categoryid"
                        :showaddtocart="showaddtocart"
                        :get-default-choice="getDefaultChoice"
                        :fill-product-with-choice="fillProductWithChoice"
                        :showratingstar="showratingstar"
                        :is-product-list-from-parent="isChildComponent">
                </Slider>
            </template>
            <template v-else-if="mode === 'products-slider-cart'">
                <SliderCart :products="products"
                            :categoryid="categoryid"
                            :showaddtocart="showaddtocart"
                            :get-default-choice="getDefaultChoice"
                            :fill-product-with-choice="fillProductWithChoice">
                </SliderCart>
            </template>
            <template v-else-if="mode === 'products-grid'">
                <Grid :products="products"
                      :categoryid="categoryid"
                      :showaddtocart="showaddtocart"
                      :get-default-choice="getDefaultChoice"
                      :fill-product-with-choice="fillProductWithChoice"
                      :showratingstar="showratingstar"
                      :is-product-list-from-parent="isChildComponent">
                </Grid>
            </template>
            <template v-else-if="mode === 'products-grid-cart'">
                <GridCart :products="products"
                          :categoryid="categoryid"
                          :showaddtocart="showaddtocart"
                          :get-default-choice="getDefaultChoice"
                          :fill-product-with-choice="fillProductWithChoice">
                </GridCart>
            </template>
            <template v-else-if="mode === 'products-grid-vp'">
                <GridVP :products="products"
                        :columnNumber="columnnumber"
                        :textInButton="textinbutton"
                        :is-product-list-from-parent="isChildComponent">
                </GridVP>
            </template>
            <template v-else-if="mode === 'products-list'">
                <List :products="products"
                      :categoryid="categoryid"
                      :showaddtocart="showaddtocart"
                      :get-default-choice="getDefaultChoice"
                      :fill-product-with-choice="fillProductWithChoice"
                      :showratingstar="showratingstar"
                      :is-product-list-from-parent="isChildComponent">
                </List>
            </template>
            <template v-else-if="mode === 'products-list-cart'">
                <ListCart :products="products"
                          :categoryid="categoryid"
                          :showaddtocart="showaddtocart"
                          :get-default-choice="getDefaultChoice"
                          :fill-product-with-choice="fillProductWithChoice">
                </ListCart>
            </template>
            <template v-else-if="mode === 'products-list-direct-buy'">
                <ListDirectBuy :products="products" :columnFormat="columnFormat">
                </ListDirectBuy>
            </template>
        </div>
    </div>
    <div v-else-if="!cartMode" class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<script>
import AddToCart from '@/components/AddToCart.vue';
import Grid from '@/components/productlist/types/Grid.vue';
import GridCart from '@/components/productlist/types/GridCart.vue';
import GridVP from '@/components/productlist/types/GridVP.vue';
import List from '@/components/productlist/types/List.vue';
import ListCart from '@/components/productlist/types/ListCart.vue';
import ListDirectBuy from "@/components/productlist/types/ListDirectBuy.vue";
import Slider from '@/components/productlist/types/Slider.vue';
import SliderCart from '@/components/productlist/types/SliderCart.vue';
import Pg from "@/services/pg";
import {useTrackingStore} from "@/store/tracking";
import AlreadyOrderedProducts from "@/services/alreadyOrderedProducts";
import Utils from "@/services/utils";

export default {
    name: 'ProductList',
    props: {
        categoryid: String,
        mode: String,
        count: Number,
        showaddtocart: Boolean,
        postMountedProducts: Array,
        cartMode: false,
        showratingstar: Boolean,
        columnnumber: String,
        textinbutton: String,
        columnFormat: String
    },
    components: {
        AddToCart,
        Grid,
        GridCart,
        GridVP,
        List,
        ListCart,
        ListDirectBuy,
        Slider,
        SliderCart
    },
    data() {
        return {
            products: [], // final products to display
            isChildComponent: false,
            isLoading: false,
            productsTmp: [], // used to store products before filtering
        }
    },
    watch: {
        postMountedProducts: function (products) {
            this.products = products;
            this.isChildComponent = true;
        },
    },
    async mounted() {
        if (['extrasell', 'extraproducts'].includes(this.categoryid)) {
            return;
        }

        if (this.shouldHideProducts()) {
            this.isLoading = true
        }

        await this.manageProducts()
        console.log('productlistproducts', this.products)
    },
    methods: {
        async manageProducts() {
            const currentCategoryId = this.categoryid || window.category.id;
            if (currentCategoryId && window.productList && window.productList[currentCategoryId]) {

                this.setProductsTmp(currentCategoryId)

                if (this.shouldHideProducts()) {
                    await this.hideAlreadyOrderedProducts()
                    this.isLoading = false
                } else {
                    this.products = this.orderProductsByPosition(this.productsTmp);
                }

            }
        },
        setProductsTmp(categoryId) {
            const categoryItems = window.productList[categoryId].products;
            if (this.count) {
                this.productsTmp = categoryItems.slice(0, this.count);
            } else {
                this.productsTmp = categoryItems;
            }
        },
        getDefaultChoice(listItem) {
            const defaultChoice = listItem.choices.find(item => item.default && item.show);
            return defaultChoice ? defaultChoice : listItem.choices.find(item => item.show);
        },
        fillProductWithChoice(product) {
            if (product.choice) {
                return product;
            }

            product.choice = this.getDefaultChoice(product);
            return product;
        },
        displayDuration(duration, startingPrice = false) {
            return Utils.displayDuration(duration, this.$i18n.locale, startingPrice);
        },
        shouldHideProducts() {
            return window.globalConfig?.hideAlreadyOrderedProducts === true
        },
        async hideAlreadyOrderedProducts() {
            try {
                const affiliateId = window.globalConfig.affiliateId;

                const contactPolarisId = useTrackingStore().urlParams?.sfId || JSON.parse(Utils.getCookie('atlasData'))?.sfId

                if (AlreadyOrderedProducts.getAlreadyOrderedProducts()?.length > 0) {
                    const productsAlreadyOrdered = AlreadyOrderedProducts.getAlreadyOrderedProducts()
                    this.filterProductsAlreadyOrdered(productsAlreadyOrdered)
                    return;
                }

                if (!affiliateId || !contactPolarisId) {
                    this.products = this.orderProductsByPosition(this.productsTmp)
                    return;
                }

                const productsAlreadyOrdered = await Pg.getActiveProducts({
                    contactPolarisId: contactPolarisId,
                    affiliateId: affiliateId
                })

                if (!productsAlreadyOrdered?.length) {
                    this.products = this.orderProductsByPosition(this.productsTmp)
                    return
                }

                this.filterProductsAlreadyOrdered(productsAlreadyOrdered)

                AlreadyOrderedProducts.setAlreadyOrderedProducts(productsAlreadyOrdered)

            } catch (e) {
                this.products = this.orderProductsByPosition(this.productsTmp)
                console.error(e)
            }
        },
        filterProductsAlreadyOrdered(productsAlreadyOrdered) {
            const productsTemp = this.productsTmp.filter((productList) => {
                if (!productsAlreadyOrdered.includes(productList.salesforceId)) {
                    return productList
                }
            })
            this.products = this.orderProductsByPosition(productsTemp)
        },
        orderProductsByPosition(products) {
            return products.sort((a, b) => {
                const aPositionDefined = !isNaN(a.position) && a.position !== null;
                const bPositionDefined = !isNaN(b.position) && b.position !== null;

                if (!aPositionDefined && !bPositionDefined) return a.name.localeCompare(b.name);
                if (!aPositionDefined && bPositionDefined) return 1;
                if (aPositionDefined && !bPositionDefined) return -1;
                if (a.position !== b.position) return a.position - b.position;
                return a.name.localeCompare(b.name);
            });
        }
    }
}
</script>