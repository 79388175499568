<template>
    <template v-if="!loading">
        <productlist categoryid="extraproducts" :mode="mode" :count="count" showaddtocart
                     :postMountedProducts="extraProducts" :columnFormat="columnformat"/>
    </template>
</template>

<script>
import productlist from '@/components/productlist/ProductList.vue';
import FetchFile from "@/services/fetchFile";
import ExtraSellService from "@/services/extraSell";
import {useCartStore} from "@/store/cart";

export default {
    name: 'ProductExtraProducts',
    components: {
        productlist
    },
    data() {
        return {
            loading: true,
            extraProductReference: null,
            extraProducts: []
        }
    },
    props: {
        mode: {
            type: String,
            default: 'products-grid'
        },
        count: {
            type: Number,
            default: 3
        },
        columnformat: {
            type: String,
            default: '4/8'
        }
    },
    computed: {
        cart() {
            return useCartStore().cart;
        }
    },
    mounted() {
        this.updateExtraProducts();
    },
    methods: {
        findExtraProductReference() {
            return window.productDetails;
        },
        updateExtraProducts() {
            this.loading = true;

            if (!window.productDetails) {
                return;
            }

            this.extraProductReference = this.findExtraProductReference();

            if (!this.extraProductReference) {
                return;
            }

            let totalExtraProducts = 0;
            for (const extra of this.extraProductReference.extrasells) {
                this.loading = false;
                document.addEventListener('change-information-extra-product', event => {
                    this.extraProducts.forEach(product => {
                        if (product.code === event.detail.productCode) {
                            switch (event.detail.typeChange) {
                                case 'images-url':
                                    product.images.forEach(image => {
                                        image.src = image.src.replace(event.detail.replaceFrom, event.detail.replaceTo);
                                    });
                                    break;
                                default:
                                    break;
                            }
                        }
                    });
                });
                new FetchFile().fetch(
                    `/data/products/${extra.productId}.json`, ({ data }) => {
                        this.extraProducts = ExtraSellService.getExtraProducts(data, this.extraProductReference.extrasells,this.cart.items, this.extraProducts, this.mode)
                        totalExtraProducts++;
                        if (totalExtraProducts === this.extraProductReference.extrasells.length) {
                            // Due to time to rendering
                            setTimeout(() => {
                                document.dispatchEvent(new Event('extra-products-loaded'));
                            }, 1);
                        }
                    }
                );
            }
        },
    }
}
</script>