<template>
    <template v-if="cart?.items?.length">
        <form method="post" action="" @submit.prevent="applyCouponAction">
            <input v-model="coupon" type="text" :placeholder="$t('labels.placeholderCoupon') " name="coupon"/>
            <button type="submit" name="applycoupon">{{ $t('labels.applycoupon') }}</button>
        </form>
    </template>
    <div v-for="message in messages" :class="`alert alert-${message.type}`" role="alert">
        {{ message.message }}
    </div>
</template>
<script>
import {useCartStore} from '@/store/cart.js';
import CartService from "@/services/cart";
import CouponService from "@/services/coupon";

export default {
    name: 'CartCoupon',
    data() {
        return {
            coupon: '',
            messages: [],
            messageTimeout: null
        }
    },
    computed: {
        cart() {
            return useCartStore().cart;
        }
    },
    watch: {
        messages: {
            handler(newValue, oldValue) {
                if (this.messages.length === 0) {
                    return;
                }
                if (this.messageTimeout) {
                    window.clearTimeout(this.messageTimeout);
                }
                this.messageTimeout = window.setTimeout(() => {
                    this.messages = [];
                }, 5000);
            },
            deep: true
        }
    },
    methods: {
        applyCouponAction(event) {
            if (this.coupon === '') {
                this.messages.push({'type': 'danger', 'message': this.$t('messages.emptyCoupon')});
                return;
            }

            this.coupon = this.coupon.toUpperCase().trim();

            const minifiedCart = CartService.minifyCart();
            const couponsCodes = minifiedCart.coupons ? Object.keys(minifiedCart.coupons) : [];
            if (!couponsCodes.includes(this.coupon)) {
                if (!minifiedCart.coupons) {
                    minifiedCart.coupons = {};
                }
                minifiedCart.coupons[this.coupon] = {code: this.coupon};
            }

            CouponService.checkCoupons(minifiedCart).then((response) => {
                for (const coupon of Object.values(response.data.cart.coupons)) {
                    if (coupon.isValid === false) {
                        let translation = '';
                        if (this.$te(`messages.invalidCoupon.${coupon.reason}`)) {
                            translation = this.$t(`messages.invalidCoupon.${coupon.reason}`, {
                                invalidCouponCode: coupon.code,
                                invalidCouponReason: coupon.reason
                            });
                        } else {
                            translation = this.$t('messages.invalidCoupon.DEFAULT', {
                                invalidCouponCode: coupon.code,
                                invalidCouponReason: coupon.reason
                            });
                        }
                        this.messages.push({
                            type: 'danger',
                            message: translation
                        });
                    } else {
                        this.messages.push({
                            type: 'success',
                            message: this.$t('messages.successfulCoupon', {validCouponCode: coupon.code})
                        });
                    }
                }
            }).catch((error) => {
                this.messages.push({'type': 'danger', 'message': this.$t('messages.errorCoupon')});
            });
        }
    }
}
</script>