<template>
    <div class="payment-form">
        <ul v-if="!isEligibleToOneClick">
            <li v-if="isBankCardAvailable" class="bankcard">
                <input type="radio" id="bank_card" :value="'bank_card'" v-model="selectedPaymentMethod"/>
                <label for="bank_card">{{ $t('labels.bankCard') }}</label>
            </li>
            <li v-if="isSepaAvailable" class="sepa">
                <input type="radio" id="sepa" :value="'sepa'" v-model="selectedPaymentMethod"/>
                <label for="sepa">{{ $t('labels.sepa') }}</label>
            </li>
        </ul>

        <div v-if="!isEligibleToOneClick && hasPaymentError" class="alert alert-warning">
            {{ $t("messages.paymentError") }}
        </div>
        <OneClick v-if="isEligibleToOneClick" class="btn-1c"/>
        <iframe
            v-else-if="isFormValid && iframeUrl"
            ref="paymentIFrame"
            v-show="!loading"
            :src="iframeUrl"
            allowtransparency="true"
            class="vanguard-payment-iframe"
            :style="`height: ${selectedPaymentMethod === 'sepa' ? iframeHeightPalyance : iframeHeightOgone}px`"
            @load="initPaymentIFrame"
         ></iframe>
        <div v-if="isFormValid && loading && !isEligibleToOneClick" class="loading"></div>
    </div>
</template>

<script>
import {useDraftStore} from '@/store/draft.js';
import {useCartStore} from '@/store/cart.js';
import {useAspspStore} from "@/store/aspsp";
import {useTrackingStore} from "@/store/tracking";
import {constants} from '@/constants';
import OneClick from '@/components/checkout/OneClick';
import Palyance from '@/services/palyance';
import OneClickService from '@/services/payment/oneClick';
import Logger from "@/services/logger";
import Navigation from "@/services/navigation";

export default {
    name: 'CheckoutPaymentForm',
    components: {OneClick},
    data() {
        return {
            selectedPaymentMethod: null,
            iframeHeightPalyance: 450,
            iframeHeightOgone: 520,
            iframeUrl: null,
            paymentFormLoading: false,
            hasPaymentError: false,
            isBankCardAvailable: true,
            isSepaAvailable: true
        }
    },
    computed: {
        loading() {
            return useDraftStore().isSendingDraftOrder || this.paymentFormLoading;
        },
        isFormValid() {
            return useDraftStore().isFormValid
        },
        cart() {
            return useCartStore().cart;
        },
        order() {
            return useDraftStore().order;
        },
        isEligibleToOneClick() {
          return OneClickService.isEligibleToOneClick();
        }
    },
    watch: {
        selectedPaymentMethod: function (newVal, oldVal) {
            useDraftStore().selectPaymentMethod(newVal);
            this.setAspspData(newVal);
            this.iframeUrl = null;
            this.updateIframeUrl();
        },
        isFormValid: function (newVal, oldVal) {
            this.iframeUrl = null;
            if (newVal) {
                this.updateIframeUrl();
            }
        }
    },
    mounted() {
        this.setPaymentMethodAvailability();

        if (!this.isSepaAvailable && !this.isBankCardAvailable) {
            console.error('No payment method available');
            return;
        }

        if (useDraftStore().selectedPaymentMethod) {
            this.selectedPaymentMethod = useDraftStore().selectedPaymentMethod;
        }

        if (this.isSepaAvailable) {
            this.selectedPaymentMethod = constants.PAYMENT_METHOD_SEPA;
        } else if (this.isBankCardAvailable) {
            this.selectedPaymentMethod = constants.PAYMENT_METHOD_BANK_CARD;
        }

        this.getAspspEligibilityData();
    },
    methods: {
        setPaymentMethodAvailability() {
            // if config not exists : display both payment methods or config exists and bank_card is available
            this.isBankCardAvailable = !window.globalConfig.paymentMethodsAvailable ||
                !window.globalConfig.paymentMethodsAvailable.includes(',') ||
                window.globalConfig.paymentMethodsAvailable.split(',').includes('bank_card');

            // if config not exists : display both payment methods or config exists and bank_card is available
            this.isSepaAvailable = !window.globalConfig.paymentMethodsAvailable ||
                !window.globalConfig.paymentMethodsAvailable.includes(',') ||
                window.globalConfig.paymentMethodsAvailable.split(',').includes('sepa');
        },
        updateIframeUrl() {
            if (!window.globalConfig || !this.isFormValid) {
                return null;
            }

            this.paymentFormLoading = true;

            if (this.selectedPaymentMethod === constants.PAYMENT_METHOD_SEPA) {
              this.iframeUrl = `${process.env.VUE_APP_PALYANCE_APP_FRONT_URL}/sepa/capture/?${this.getPalyanceV2QueryParams()}`;
            } else {
                this.iframeUrl = `${process.env.VUE_APP_PALYANCE_APP_FRONT_URL}/bankcard/capture/?${this.getPalyanceV2QueryParams()}`;
            }
        },
        getAspspEligibilityData() {
            let sfId = null, walletIdentifier = null;
            const regexIdentifier = /^[a-zA-Z0-9]*$/;

            if (useTrackingStore().urlParams.sfId) {
                sfId = useTrackingStore().urlParams.sfId;

                if (sfId && !regexIdentifier.test(sfId)) {
                    sfId = sfId.replace(/[^a-z0-9.]+/ig, '');
                }
            }

            if (useTrackingStore().urlParams.walletId && regexIdentifier.test(useTrackingStore().urlParams.walletId)) {
                useDraftStore().prefillLoading(true);
                walletIdentifier = useTrackingStore().urlParams.walletId;

                Palyance.getAspspEligibility(
                    walletIdentifier,
                    this.selectedPaymentMethod, window.globalConfig.affiliateId
                ).then(data => {
                    useDraftStore().prefillLoading(false);

                    if (JSON.stringify(data) === '{}') {
                        return;
                    }

                    useAspspStore().eligible(true);

                    if(data.bank_card.paymentIdentifier) {
                      useAspspStore().updateAspspEligibleBankCard(true)
                    }
                    if(data.sepa.paymentIdentifier) {
                      useAspspStore().updateAspspEligibleSepa(true)
                    }

                    useAspspStore().updateAspspPaymentInformations(data);

                    this.setAspspData(this.selectedPaymentMethod)
                    this.setAspspContent(this.selectedPaymentMethod)

                    this.updateIframeUrl()
                }).catch(error => {
                    console.error(error);
                    Logger.error('Error on getAspspEligibility call', error);
                });
            }
        },
        formatFullAddress(data) {
            const chunks = [];

            if (data.BILLINGSTREET3__C) {
                chunks.push(data.BILLINGSTREET3__C);
            }

            chunks.push(`${data.BILLINGZIPCODE__C} ${data.BILLINGCITY__C}`);

            return chunks.join(', ');
        },
        getPalyanceV2QueryParams() {
            const exceptionUrl = `${window.location.origin}${window.globalConfig.errorPage}`;
            const waitingUrl = `${window.location.origin}${window.globalConfig.waitingPage}`;
            const waitingDoubleOptinUrl = `${window.location.origin}${window.globalConfig.waitingDoubleOptinPage}`;

            const paramPlus = this.buildParamPlus();

            const payload = {
                amount: this.cart.total,
                totalAmount: this.cart.total,
                merchantIdentifier: window.globalConfig.affiliateId,
                merchantName: window.globalConfig.brandName,
                doubleOptin: useAspspStore().isDoubleOptin ? 1 : 0,
                lang: window.globalConfig.lang,
                orderId: this.order.externalOrderId,
                billingInformations: {
                    firstName: this.order.billingFirstName,
                    lastName: this.order.billingLastName,
                    address: this.order.billingStreet1 || '',
                    address2: this.order.billingStreet2 || '',
                    address3: this.order.billingStreet3 || '',
                    address4: this.order.billingStreet4 || '',
                    phone: this.order.billingPhoneNumber || '',
                    zipCode: this.order.billingZipCode,
                    city: this.order.billingCity,
                    country: this.order.billingCountry,
                    email: this.order.billingEmail.toLowerCase()
                },
                technicalContract: useDraftStore().areCgvApproved ? 1 : 0,
                aspspContract: useDraftStore().areCgsApproved ? 1 : 0,
                sourceUrl: encodeURIComponent(document.location.href),
                formIsValid: useDraftStore().isFormValid,
                productName: 'shop',
                browserData: this.presentBrowserData(),
                waitingUrl: waitingUrl,
                exceptionUrl: exceptionUrl
            };

            if ((this.selectedPaymentMethod === constants.PAYMENT_METHOD_SEPA && useAspspStore().isAspspEligibleSepa) || (this.selectedPaymentMethod === constants.PAYMENT_METHOD_BANK_CARD && useAspspStore().isAspspEligibleBankCard) ) {
                const regex = /^[a-zA-Z0-9]*$/;
                const walletId = useTrackingStore().urlParams.walletIdentifier || useTrackingStore().urlParams.walletId;
                const queryParams = Navigation.getQueryParams();
                const paymentId = queryParams.get('paymentId') || useAspspStore().paymentIdentifier; // paymentId is used when recapture

                payload.walletIdentifier = regex.test(walletId) ? walletId : null;
                payload.paymentInformationIdentifier = paymentId;
                payload.mandateIdentifier = useAspspStore().paymentIdentifier;
                payload.paymentInformationObfuscated = useAspspStore().paymentAccountIdentifier;
                payload.pciToken = useAspspStore().pciToken;
                payload.holderName = useAspspStore().holderName;

                if (this.selectedPaymentMethod === constants.PAYMENT_METHOD_SEPA) {
                    payload.waitingUrl = waitingDoubleOptinUrl;

                    paramPlus.emailDoubleOptin = useAspspStore().email;
                    paramPlus.countdownStartTimeMs = Date.now();
                }
            }

            localStorage.setItem(constants.PARAM_PLUS, JSON.stringify(paramPlus));

            const queryParams = new URLSearchParams();
            queryParams.append('json', JSON.stringify(payload));

            return queryParams.toString();
        },
        presentBrowserData() {
            if (typeof window.screen === 'undefined') {
                window.screen = {};
            }

            if (typeof window.navigator === 'undefined' || typeof window.navigator.javaEnabled !== 'function') {
                window.navigator = {
                    javaEnabled() {
                        return false;
                    }
                };
            }

            return {
                colorDepth: window.screen.colorDepth,
                javaEnabled: window.navigator.javaEnabled().toString(),
                language: window.navigator.language,
                screenHeight: window.screen.height,
                screenWidth: window.screen.width,
                timeZone: new Date().getTimezoneOffset()
            };
        },
        buildParamPlus() {
            const paramPlus = {};

            if (window.location.search.length > 0) {
                window.location.search.slice(1).split('&').forEach(pair => {
                    const row = pair.split('=');
                    paramPlus[row[0]] = row[1];
                });
            }

            paramPlus.orderId = this.order.externalOrderId;
            //paramPlus.confirmationUrl = confirmationUrl;
            //paramPlus.exceptionUrl = exceptionUrl;
            paramPlus.paymentType = this.selectedPaymentMethod;
            paramPlus.action = 'capture';

            return paramPlus;
        },
        initPaymentIFrame() {
            // Listen to IFrame messages to retrieve payment data
            window.addEventListener('message', this.iframeMessageCallback, false);

            if (!this.$refs.paymentIFrame) {
                console.error('Unable to retrieve payment IFrame DOM node');
            }

            const paymentIFrame = this.$refs.paymentIFrame.contentWindow;
            paymentIFrame.postMessage('FrameHeightPalyance', '*');

            this.paymentFormLoading = false;
        },
        iframeMessageCallback(event) {
            // Check whether message origin is Palyance origin
            if (event.origin !== process.env.VUE_APP_PALYANCE_APP_FRONT_URL) {
                console.debug('Origin does not match with Palyance origin', event);
                return;
            }

            if (event && event.data && typeof event.data.FrameHeightPalyance !== 'undefined') {
                console.debug('event.data.FrameHeightPalyance', event.data.FrameHeightPalyance);
                this.iframeHeightPalyance = event.data.FrameHeightPalyance;
                return;
            }

            const response = event.data;
            if (typeof response.isLoaded !== 'undefined') {
                return;
            }

            // If an error occurred, display error message then reset IFrame
            if (!response.status) {
                console.error('Palyance Payment error', response.data);
                this.hasPaymentError = true;
                return;
           }
        },
      setAspspData(paymentMethod) {
        useAspspStore().updateAspspData(paymentMethod)
      },
      setAspspContent(paymentMethod) {
        const data = useAspspStore().aspspPaymentInformations[paymentMethod];
        useAspspStore().updatePrefillData(data.content);
        useAspspStore().updateEmail(data.content.EMAIL);
        useAspspStore().updatAspspFullAdress(this.formatFullAddress(data.content));
      }
    },
}
</script>