<template>
    <template v-if="cart?.items?.length">
        <button v-if="showcartbtn" type="button" @click="goToCartAction" class="cart-btn">{{
                $t('labels.cart')
            }}
        </button>
        <button v-if="showcheckoutbtn" type="button" @click="goToCheckoutAction" class="checkout-btn">
            {{ $t('labels.submit') }}
        </button>
    </template>
</template>

<script>
import {useCartStore} from '@/store/cart.js';
import Navigation from "@/services/navigation";

export default {
    name: 'CartProcess',
    props: {
        showcartbtn: Boolean,
        showcheckoutbtn: Boolean
    },
    computed: {
        cart() {
            return useCartStore().cart;
        }
    },
    methods: {
        goToCartAction() {
            Navigation.redirectUrl('/cart');
        },
        goToCheckoutAction() {
            Navigation.redirectUrl('/checkout');
        }
    }
}
</script>